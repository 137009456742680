import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import Receipt from 'components/Receipt'

function ExamplePage({ location }) {
	const pageTitle = location ? location.pathname.replace(/\//g, '') : ''
	return (
		<Layout location={location} title={pageTitle}>
			<Receipt />
		</Layout>
	)
}

ExamplePage.propTypes = {
	location: PropTypes.object,
}

export default ExamplePage
