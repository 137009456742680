import React from 'react'
import PropTypes from 'prop-types'
import { Box, Paper, withStyles } from '@material-ui/core'

const styles = theme => ({
	paper: {
		margin: 'auto',
		padding: theme.spacing(3),
		overflow: 'hidden',
		[theme.breakpoints.up('sm')]: {
			minWidth: 600,
		},
		[theme.breakpoints.up('lg')]: {
			minWidth: 936,
		},
	},
})

function PageLayout({ classes, children }) {
	return (
		<Box p="48px 36px 48px">
			<Paper className={classes.paper}>{children}</Paper>
		</Box>
	)
}

PageLayout.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PageLayout)
