import React from 'react'
import PropTypes from 'prop-types'
import { startCase } from 'lodash'
import { Map, List } from 'immutable'

const Form = ({ items, setItems, emptyItem, getTotal }) => {
	const itemKeys = emptyItem.keySeq().toArray()

	function handleRowDel(index) {
		if (items.size === 1) {
			setItems(List([emptyItem.set('id', 1)]))
		} else {
			setItems(items.delete(index))
		}
	}

	function handleAddEvent() {
		const id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36)
		setItems(items.push(emptyItem.set('id', id)))
	}

	function handleUpdateEvent(evt, index, key) {
		const value = evt?.target?.value
		setItems(items.setIn([index, key], value))
	}

	return (
		<div>
			<button type="button" onClick={handleAddEvent} className="btn btn-success pull-right">
				Add
			</button>

			<table className="table table-bordered">
				<thead>
					<tr>
						{itemKeys.map(key => (
							<th key={key}>{startCase(key)}</th>
						))}
						{getTotal && <th>Total</th>}
					</tr>
				</thead>

				<tbody>
					{items.map((item, index) => (
						<tr key={item.get('id')} className="eachRow">
							{itemKeys.map(key => (
								<td key={key}>
									<input
										type="text"
										value={item.get(key)}
										onChange={e => handleUpdateEvent(e, index, key)}
									/>
								</td>
							))}

							{getTotal && (
								<td>
									<input
										type="text"
										value={getTotal(item)}
										readOnly
										onChange={() => {}}
										style={{ pointerEvents: 'none', textAlign: 'center' }}
									/>
								</td>
							)}
							<td className="del-cell">
								<input
									type="button"
									onClick={() => handleRowDel(index)}
									value="X"
									className="del-btn"
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

Form.propTypes = {
	items: PropTypes.instanceOf(List).isRequired,
	setItems: PropTypes.func.isRequired,
	emptyItem: PropTypes.instanceOf(Map).isRequired,
	getTotal: PropTypes.func,
}

Form.defaultProps = {
	getTotal: undefined,
}

export default Form
