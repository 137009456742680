import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Document, Page, View, Text, Link, Image, Font, StyleSheet } from '@react-pdf/renderer'
import styled from '@react-pdf/styled-components'
import moment from 'moment'

const format = 'DD/MM/YYYY'

const formatNumber = number =>
	parseFloat(number)
		.toLocaleString('de-DE')
		.replace('.', ' ')

Font.register({
	family: 'Roboto',
	fonts: [
		{ src: '/fonts/Roboto/Roboto-Light.ttf', fontWeight: 300 },
		{ src: '/fonts/Roboto/Roboto-Regular.ttf', fontWeight: 400 },
		{ src: '/fonts/Roboto/Roboto-Bold.ttf', fontWeight: 700 },
	],
})

const TextH1 = styled.Text`
	font-size: 22px;
	font-weight: bold;
	opacity: 1;
`

const TextH2 = styled.Text`
	font-size: 15px;
	font-weight: bold;
	opacity: 1;
`

const TextBody1 = styled.Text`
	font-size: 11px;
	line-height: 1.5px;
	font-weight: light;
	opacity: 1;
`

const TextBody2 = styled.Text`
	font-size: 11px;
	font-weight: normal;
	opacity: 1;
`

const Hr = styled.View`
	min-width: 100%;
	min-height: ${({ smaller }) => (smaller ? '1em' : '1.5em')};
	display: block;
	height: ${({ smaller }) => (smaller ? '1em' : '1.5em')};
	width: 100%;
	background-color: #474747;
	margin: 8px 0px;
	opacity: ${({ opacity }) => opacity || 1};
`

const styles = StyleSheet.create({
	pageBackground: {
		position: 'absolute',
		minWidth: '100%',
		minHeight: '100%',
		display: 'block',
		height: '100%',
		width: '100%',
	},

	paidIcon: {
		width: '100px',
	},

	container: {
		display: 'flex',
		paddingTop: '150px',
		fontFamily: 'Roboto',
		color: '#474747',
	},

	contentContainer: {
		padding: '10px 40px',
	},

	subHeaderContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	metaInfoContainer: {
		display: 'flex',
		alignItems: 'flex-end',
	},

	metaInfoItem: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '160px',
	},

	metaInfoItemHeading: {
		width: '80px',
		textAlign: 'right',
	},

	customerInfoItem: {
		display: 'flex',
		flexDirection: 'row',
	},

	basketItemHeadingContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '0px 40px',
	},

	basketItemContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '0px 40px',
	},

	basketItem: {
		flex: 1,
		lineHeight: 'normal',
		textAlign: 'right',
	},

	summaryContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '40px 12px',
	},

	summaryLeftContainer: {
		flex: 9,
		backgroundColor: 'grey',
		padding: '16px',
		margin: '8px',
		opacity: 0.2,
	},

	summaryRightContainer: {
		flex: 8,
		display: 'flex',
		justifyContent: 'flex-end',
		paddingBottom: '20px',
	},

	sumaryHeadingContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '0px 20px',
	},

	summaryPriceItem: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '220px',
	},

	summaryPriceItemHeading: {
		width: '80px',
		textAlign: 'right',
	},

	logoContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	logo: {
		width: '70px',
		paddingBottom: '30px',
	},
})

const Template = ({ items, customerInfo, receiptInfo, isPaid }) => {
	const { paid = 0, name = '', address = '' } = customerInfo
	const { number = 1, date = moment(), dueDate = moment().add(2, 'months') } = receiptInfo
	const total = parseFloat(items.reduce((acc, { price, quantity }) => acc + quantity * price, 0))

	return (
		<>
			<Image style={styles.pageBackground} src="/images/receipt-template/background.jpg" />
			<View style={styles.container}>
				<View style={styles.contentContainer}>
					<View style={styles.logoContainer}>
						<Image style={styles.logo} src="/images/logo.jpg" />
						{isPaid && <Image style={styles.paidIcon} src="/images/receipt-template/paid-stamp.jpg" />}
					</View>
					<View style={styles.subHeaderContainer}>
						<View>
							<TextBody1>Bamako, Mali</TextBody1>
							<TextBody1>+223 76 31 33 43</TextBody1>
							<TextBody1>meilyfabrics@gmail.com</TextBody1>
						</View>
						<View style={styles.metaInfoContainer}>
							<TextH1 style={{ marginBottom: '16px' }}>Facture</TextH1>
							<View style={styles.metaInfoItem}>
								<TextBody2 style={styles.metaInfoItemHeading}>Facture N°:</TextBody2>
								<TextBody1>{number}</TextBody1>
							</View>
							<View style={styles.metaInfoItem}>
								<TextBody2 style={styles.metaInfoItemHeading}>Date:</TextBody2>
								<TextBody1>{date.format(format)}</TextBody1>
							</View>
							<View style={styles.metaInfoItem}>
								<TextBody2 style={styles.metaInfoItemHeading}>Échéance:</TextBody2>
								<TextBody1>{dueDate.format(format)}</TextBody1>
							</View>
						</View>
					</View>
				</View>

				<Hr opacity={0.1} style={{ margin: '8px 0px 0px' }} />

				<View style={styles.contentContainer}>
					<View style={styles.subHeaderContainer}>
						<View style={{ display: 'flex', alignItems: 'flex-start' }}>
							<View style={styles.customerInfoItem}>
								<TextBody2 style={{ width: '80px' }}>Facturation:</TextBody2>
								<TextBody1>{name}</TextBody1>
							</View>
							<View style={styles.customerInfoItem}>
								<TextBody2 style={{ width: '80px' }}></TextBody2>
								<TextBody1>{address}</TextBody1>
							</View>
						</View>
					</View>
				</View>

				<Hr />
				<View style={styles.basketItemHeadingContainer}>
					<TextBody2 style={{ flex: 2 }}>Désignation</TextBody2>
					<TextBody2 style={styles.basketItem}>Qté</TextBody2>
					<TextBody2 style={styles.basketItem}>P.U</TextBody2>
					<TextBody2 style={styles.basketItem}>Montant</TextBody2>
				</View>
				<Hr style={{ marginBottom: '4px' }} />

				{(items || []).map(({ name, quantity, price }) => (
					<Fragment key={name}>
						<View style={styles.basketItemContainer}>
							<TextBody1 style={{ flex: 2, lineHeight: 'normal' }}>{name}</TextBody1>
							<TextBody1 style={styles.basketItem}>{quantity}</TextBody1>
							<TextBody1 style={styles.basketItem}>{formatNumber(price)} CFA</TextBody1>
							<TextBody1 style={styles.basketItem}>{formatNumber(quantity * price)} CFA</TextBody1>
						</View>
						<Hr opacity={0.1} smaller style={{ margin: '4px 0px' }} />
					</Fragment>
				))}

				<View style={styles.summaryContainer}>
					<View style={styles.summaryLeftContainer}>
						<TextBody2 style={{ fontWeight: 'bold' }}>Instructions de paiement</TextBody2>
						<TextBody1 style={{ marginTop: '10px' }}>Le paiement peut se faire par Paypal vers</TextBody1>
						<TextBody1>
							<Link src="mailto:meilyfabrics@gmail.com">meilyfabrics@gmail.com</Link> ou par espèces lors
							de la livraison.
						</TextBody1>
						<TextBody1 style={{ marginTop: '18px' }}>
							Merci de votre confiance et à très bientôt chez Meily !
						</TextBody1>
					</View>

					<View style={styles.summaryRightContainer}>
						<View style={{ display: 'flex', alignItems: 'flex-end', paddingRight: '20px' }}>
							<View style={styles.summaryPriceItem}>
								<TextBody1 style={styles.summaryPriceItemHeading}>Sous-total</TextBody1>
								<TextBody1>{formatNumber(total)} CFA</TextBody1>
							</View>
							<View style={styles.summaryPriceItem}>
								<TextBody1 style={styles.summaryPriceItemHeading}>Total TTC</TextBody1>
								<TextBody1>{formatNumber(total)} CFA</TextBody1>
							</View>
							<View style={styles.summaryPriceItem}>
								<TextBody1 style={styles.summaryPriceItemHeading}>Payé</TextBody1>
								<TextBody1>{formatNumber(paid)} CFA</TextBody1>
							</View>
						</View>
						<Hr />
						<View style={{ display: 'flex', alignItems: 'flex-end', paddingRight: '20px' }}>
							<View style={styles.summaryPriceItem}>
								<TextH2 style={styles.summaryPriceItemHeading}>Net à payer</TextH2>
								<TextH2>{formatNumber(total - paid)} CFA</TextH2>
							</View>
						</View>
						<Hr />
					</View>
				</View>
			</View>
		</>
	)
}

Template.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			price: PropTypes.number,
			quantity: PropTypes.number,
		})
	).isRequired,
	customerInfo: PropTypes.shape({
		name: PropTypes.string,
		address: PropTypes.string,
		paid: PropTypes.number,
	}).isRequired,
	receiptInfo: PropTypes.shape({
		number: PropTypes.number,
		date: PropTypes.instanceOf(moment),
		dueDate: PropTypes.instanceOf(moment),
	}).isRequired,
	isPaid: PropTypes.bool,
}

export const PaidTemplate = props => (
	<Document>
		<Page size="A4">
			<Template {...props} isPaid />
		</Page>
	</Document>
)

export const UnpaidTemplate = props => (
	<Document>
		<Page size="A4">
			<Template {...props} />
		</Page>
	</Document>
)
