import React, { useState } from 'react'
import { Grid, Button, CircularProgress, TextField } from '@material-ui/core'
import { Map, List } from 'immutable'
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer'
import moment from 'moment'

import PageLayout from '../Layout/PageLayout'
import { PaidTemplate, UnpaidTemplate } from './Template'
import Form from './Form'

const emptyItem = Map({
	name: '',
	price: 0,
	quantity: 1,
})

const format = 'YYYY-MM-DD'

const receiptForm = [
	{ key: 'number', label: 'N° facture', type: 'number' },
	{ key: 'date', label: "Date d'émission", type: 'date', helperText: '⚠️ mm/dd/yyyy ⚠️' },
	{ key: 'dueDate', label: "Date d'échéance", type: 'date', helperText: '⚠️ mm/dd/yyyy ⚠️' },
]

const customerForm = [
	{ key: 'name', label: 'Nom du client' },
	{ key: 'address', label: 'Adresse du client' },
	{ key: 'paid', label: 'Montant déja payé', type: 'number' },
]

const formatValue = (value, type) => {
	switch (type) {
		case 'date':
			return moment(value)
		case 'number':
			return parseFloat(value)
		default:
			return value
	}
}

const Receipt = () => {
	const [items, setItems] = useState(List([emptyItem.set('id', 1)]))
	const [customerInfo, setCustomerInfo] = useState(Map({ name: '', address: '', paid: 0 }))
	const [receiptInfo, setReceiptInfo] = useState(
		Map({ number: 1, date: moment(), dueDate: moment().add(3, 'weeks') })
	)
	const [templateProps, setTemplateProps] = useState({ items: [], customerInfo: {}, receiptInfo: {} })

	function updateTemplateProps() {
		setTemplateProps({
			items: items.toJS(),
			customerInfo: customerInfo.toJS(),
			receiptInfo: receiptInfo.toJS(),
		})
	}

	function onReceiptInfoChange(key, value, type) {
		if (key === 'date') {
			setReceiptInfo(
				receiptInfo.set(key, formatValue(value, type)).set('dueDate', formatValue(value, type).add(3, 'weeks'))
			)
		} else {
			setReceiptInfo(receiptInfo.set(key, formatValue(value, type)))
		}
	}

	function onCustomerInfoChange(key, value, type) {
		setCustomerInfo(customerInfo.set(key, formatValue(value, type)))
	}

	const isStaticEnv = typeof window === 'undefined'

	return (
		<PageLayout>
			<Grid container direction="column" alignItems="center" spacing={6}>
				<Grid item container justify="center" spacing={2}>
					<Grid item container justify="center" spacing={2}>
						{receiptForm.map(({ key, type, ...item }) => (
							<Grid key={key} item>
								<TextField
									id={`receipt-${key}`}
									type={type}
									value={type === 'date' ? receiptInfo.get(key).format(format) : receiptInfo.get(key)}
									onChange={({ target: { value } }) => onReceiptInfoChange(key, value, type)}
									{...item}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>

				<Grid item container justify="center" spacing={2}>
					{customerForm.map(({ key, type, ...item }) => (
						<Grid key={key} item>
							<TextField
								id={`customer-${key}`}
								type={type}
								value={customerInfo.get(key)}
								onChange={({ target: { value } }) => onCustomerInfoChange(key, value, type)}
								{...item}
							/>
						</Grid>
					))}
				</Grid>

				<Grid item>
					<Form
						items={items}
						setItems={setItems}
						emptyItem={emptyItem}
						getTotal={item => item.get('price') * item.get('quantity')}
					/>
				</Grid>

				{!isStaticEnv && (
					<Grid item>
						<Button color="primary" variant="contained">
							<PDFDownloadLink
								style={{ textDecoration: 'none', color: 'inherit' }}
								document={<UnpaidTemplate {...templateProps} />}
								fileName={`facture-${receiptInfo.get('number')}.pdf`}
							>
								{({ blob, url, loading, error }) =>
									loading ? <CircularProgress size={17} color="secondary" /> : 'Générer la facture'
								}
							</PDFDownloadLink>
						</Button>

						<Button color="primary" variant="contained" style={{ marginLeft: 16 }}>
							<PDFDownloadLink
								style={{ textDecoration: 'none', color: 'inherit' }}
								document={<PaidTemplate {...templateProps} />}
								fileName={`facture-${receiptInfo.get('number')}-payee.pdf`}
							>
								{({ blob, url, loading, error }) =>
									loading ? (
										<CircularProgress size={17} color="secondary" />
									) : (
										'Générer la facture payée'
									)
								}
							</PDFDownloadLink>
						</Button>

						<Button
							color="primary"
							variant="contained"
							style={{ marginLeft: 16 }}
							onClick={updateTemplateProps}
						>
							Rafraichir la previsualisation
						</Button>
					</Grid>
				)}

				{!isStaticEnv && (
					<Grid item>
						<PDFViewer width="800px" height="800px">
							<UnpaidTemplate {...templateProps} />
						</PDFViewer>
					</Grid>
				)}
			</Grid>
		</PageLayout>
	)
}

Receipt.propTypes = {}

export default Receipt
